<template>
	<div class="ratings">
		<div class="rating" v-for="rating in data">
			<div class="rating__img">
				<img v-lazy="rating.image">
			</div>
			<div class="rating__content">
				<div class="rating__content__item" v-for="item in rating.list">
					<p class="rating--title" v-html="item.title"></p>
					<p class="rating--desc" v-html="item.text"></p>
				</div>
			</div>
		</div>
	</div>
</template>



<style lang="sass">
	@import '@/assets/sass/Rating'
</style>

<script>
	export default {
		props: ['data'],
	}
</script>